import { LevelSet, Type, Id } from '../models'

export class WipActivity {
  public tags: Id[] = [];

  /**
   *
   */
  constructor(
    public name: string,
    public type: Type
  ) {}
}

export class WipEntry {
  public levels: LevelSet

  private _notes = "";
  public get notes(): string {
    return this._notes;
  }
  public set notes(newNotes: string) {
    this._notes = newNotes;
    //TODO: generate WIP activities as needed
  }


  private _isNew: boolean;
  public get isNew(): boolean {
    return this._isNew;
  }


  public existingActivities: Id[] = []
  public newActivities: WipActivity[] = []
  public datetime: Date = new Date()

  constructor(initialLevels: LevelSet, isNew: boolean) {
    this.levels = initialLevels.copy()
    this._isNew = isNew
  }
}