<template>
  <main class="content">
    <section id="levels-entry-instructions">
      <p>On a scale of 1 to 10, how fulfilled are you feeling in each of the following areas?</p>
    </section>
    <section id="level-inputs">
      <div class="level-input">
        <div>
          <label for="level-input-1" class="field-name">Physical</label>
          <div class="row">
            <button class="small bg-color-1" @click="decrementPhysical" :disabled="physical < 2">−</button>
            <input id="level-input-1" type="number" max="10" min="1" @input="updatePhysical" :value="physical"/>
            <button class="small bg-color-1" @click="incrementPhysical" :disabled="physical > 9">+</button>
          </div>
        </div>
      </div>
      <div class="level-input">
        <div>
          <label for="level-input-2" class="field-name">Spiritual</label>
          <div class="row">
            <button class="small bg-color-2" @click="decrementSpiritual" :disabled="spiritual < 2">−</button>
            <input id="level-input-2" type="number" max="10" min="1" @input="updateSpiritual" :value="spiritual"/>
            <button class="small bg-color-2" @click="incrementSpiritual" :disabled="spiritual > 9">+</button>
          </div>
        </div>
      </div>
      <div class="level-input">
        <div>
          <label for="level-input-3" class="field-name">Mental</label>
          <div class="row">
            <button class="small bg-color-3" @click="decrementMental" :disabled="mental < 2">−</button>
            <input id="level-input-3" type="number" max="10" min="1" @input="updateMental" :value="mental"/>
            <button class="small bg-color-3" @click="incrementMental" :disabled="mental > 9">+</button>
          </div>
        </div>
      </div>
      <div class="level-input">
        <div>
          <label for="level-input-4" class="field-name">Social</label>
          <div class="row">
            <button class="small bg-color-4" @click="decrementSocial" :disabled="social < 2">−</button>
            <input id="level-input-4" type="number" max="10" min="1" @input="updateSocial" :value="social"/>
            <button class="small bg-color-4" @click="incrementSocial" :disabled="social > 9">+</button>
          </div>
        </div>
      </div>
    </section>
    <section id="levels-entry-notes">
      <label class="field-name" for="notes">Log Entry</label>
      <div class="textarea-container">
        <textarea-expandable id="notes" placeholder="What are you feeling?" v-model="fullNotes" :focus="focus">
            <span v-if="hasPrefix">
              <span class="underline-color-1">{{physical}}</span>
              <span>-</span>
              <span class="underline-color-2">{{spiritual}}</span>
              <span>-</span>
              <span class="underline-color-3">{{mental}}</span>
              <span>-</span>
              <span class="underline-color-4">{{social}}</span>
            </span>
            <span>{{notes}}</span>
        </textarea-expandable>
      </div>
    </section>
    <section>
      <date-input id="when" v-model="date"/>
    </section>
    <teleport to="#button-bar">
      <button class="button text" @click="$emit('back')">Cancel</button>
      <button class="button" @click="next">Save</button>
    </teleport>
  </main>
</template>

<script lang="ts">
import { WipEntry } from '@/models/ephemeral/add-entry';
import { keyWipEntry } from '@/views/AddEditEntry.vue';
import TextareaExpandable from '@/components/AddEntry/TextareaExpandable.vue';
import DateInput from '@/components/general/DateInput.vue';
import { computed, defineComponent, inject as vueInject, onMounted, ref } from 'vue';
import { LevelSet } from '@/models/models';
import { StoreService, useServiceInjector } from '@/injection';

const charCodeIsDigit = (c: number) => {
  return 47 < c && c < 58;
}

const regex = new RegExp('((10|[1-9])-(10|[1-9])-(10|[1-9])-(10|[1-9]))(([^0-9]|[\\n\\r])*)$');

export default defineComponent({
  components: {
    DateInput,
    TextareaExpandable
  },
  emits: ['next', 'back'],
  setup() {
    const formData = vueInject<WipEntry>(keyWipEntry);
    if (formData == null) {
      throw new Error("WIP Entry not found!");
    }

    const spiritual = ref(formData.levels.spiritual);
    const physical = ref(formData.levels.physical);
    const social = ref(formData.levels.social);
    const mental = ref(formData.levels.mental);

    const prefix = ref("");
    const hasPrefix = computed(() => prefix.value !== '')
    const notes = ref(formData.notes);

    const fullNotes = computed({
      set: (v) => {
        if (hasPrefix.value &&
          prefix.value === v.substring(0, prefix.value.length) &&
          !charCodeIsDigit(v.charCodeAt(prefix.value.length))) {
          notes.value = v.substring(prefix.value.length);
          return;
        }
        const [hasMatch, prefixVal, physicalVal, spiritualVal, mentalVal, socialVal, rest] = regex.exec(v) || [];
        if (hasMatch) {
          prefix.value = prefixVal;
          spiritual.value = +spiritualVal;
          physical.value = +physicalVal;
          mental.value = +mentalVal;
          social.value = +socialVal;
          notes.value = rest;
        }
        else {
          notes.value = v;
          prefix.value = '';
        }
      },
      get: () => {
        return (hasPrefix.value ? [physical.value, spiritual.value, mental.value, social.value].join('-') : '') + notes.value;
      }
    });

    const focus = ref(false);

    const inject = useServiceInjector()

    const store = inject(StoreService)

    onMounted(() => {
      if (store.hasDataSync.value) {
        focus.value = true;
      }
    })

    const date = ref<Date|null>(!formData.isNew ? formData.datetime : null);

    return {
      focus,
      notes,
      spiritual,
      physical,
      social,
      mental,
      formData,
      date,
      fullNotes,
      hasPrefix,
      prefix
    }
  },
  methods: {
    next() {
      this.formData.levels = new LevelSet(
        this.physical,
        this.spiritual,
        this.mental,
        this.social
      )
      this.formData.notes = this.notes.trim()
      this.formData.datetime = this.date || new Date()
      this.$emit('next')
    },
    back() {
      this.$emit('back')
    },
    updatePhysical(e: Event) {
      this.physical = Math.max(1, Math.min(10, +(e.target as HTMLInputElement).value));
    },
    updateMental(e: Event) {
      this.mental = Math.max(1, Math.min(10, +(e.target as HTMLInputElement).value));
    },
    updateSpiritual(e: Event) {
      this.spiritual = Math.max(1, Math.min(10, +(e.target as HTMLInputElement).value));
    },
    updateSocial(e: Event) {
      this.social = Math.max(1, Math.min(10, +(e.target as HTMLInputElement).value));
    },
    incrementPhysical() {
      this.physical = Math.max(1, Math.min(10, this.physical + 1))
    },
    incrementMental() {
      this.mental = Math.max(1, Math.min(10, this.mental + 1))
    },
    incrementSocial() {
      this.social = Math.max(1, Math.min(10, this.social + 1))
    },
    incrementSpiritual() {
      this.spiritual = Math.max(1, Math.min(10, this.spiritual + 1))
    },
    decrementPhysical() {
      this.physical = Math.max(1, Math.min(10, this.physical - 1))
    },
    decrementMental() {
      this.mental = Math.max(1, Math.min(10, this.mental - 1))
    },
    decrementSocial() {
      this.social = Math.max(1, Math.min(10, this.social - 1))
    },
    decrementSpiritual() {
      this.spiritual = Math.max(1, Math.min(10, this.spiritual - 1))
    },
  }
})
</script>

<style scoped>
input[type=number] {
  color: var(--white);
  border: 2px solid var(--white-transparent);
  background-color: var(--color-bg-dark);
  width: 48px;
  text-align: center;
  font-size: 24px;
}
#levels-entry-instructions {
  margin: 10px 0 14px 0;
}
#level-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 12px;
  row-gap: 4px;
}
.level-input {
  margin-left: auto;
  margin-right: auto;
}
.level-input button {
  letter-spacing: 0;
  font-size: 1.4em;
  line-height: 0;
  height: 40px;
  min-width: 44px;
  padding: 0;
  padding-bottom: 2px;
  margin: 0;
  border-radius: 20px;
}
.level-input button:nth-of-type(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.level-input button:nth-of-type(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.level-input button:disabled {
  opacity: 0.55;
}
.level-input input {
  height: 40px;
  border-radius: 0;
}
.level-input input:focus {
  border-color: var(--white);
}
.level-input label {
  padding-left: 12px;
}
#levels-entry-notes {
  margin: 14px 0;
}
</style>