
import { defineComponent, provide, ref } from 'vue'
import LevelsEntry from '@/components/AddEntry/LevelsEntry.vue'
import LoadingScreen from '@/components/Visualization/LoadingScreen.vue'
import { WipEntry } from '@/models/ephemeral/add-entry'
import { LevelSet } from '@/models/models'
import { showToast } from '@/components/general/Toasts.vue'
import { StoreService, useServiceInjector } from '@/injection'
import { useExtendedRouter } from '@/router/extended-router'

export const keyWipEntry = 'WipEntry';

export default defineComponent({
  components: {
    LevelsEntry,
    LoadingScreen
  },
  props: {
    existingId: {
      type: Number,
      required: false
    }
  },
  setup(props) {
    const inject = useServiceInjector()

    const store = inject(StoreService)

    const router = useExtendedRouter()

    const levels = (props.existingId == null ? store.mostRecentEntrySync.value?.levels : null)
                    ?? new LevelSet(5,5,5,5)
    const wipEntry = new WipEntry(levels.copy(), props.existingId == null)

    const loading = ref(false)

    if (props.existingId != null) {
      const id = props.existingId as number
      loading.value = true
      store.getEntryById(id).then((entry) => {
        wipEntry.datetime = entry.datetime
        wipEntry.levels = entry.levels.copy()
        wipEntry.notes = entry.notes
        wipEntry.existingActivities = entry.activities
        loading.value = false
      }).catch(() => {
        router.replace({ name: "LogEntry", params: { id }})
      })
    }

    provide(keyWipEntry, wipEntry)

    const next = async () => {
      const activityIds = await store.addActivities(wipEntry.newActivities)
      wipEntry.existingActivities = wipEntry.existingActivities.concat(activityIds)
      wipEntry.newActivities = []
      if (props.existingId != null) {
        await store.updateEntry(props.existingId, wipEntry)
      }
      else {
        await store.addEntry(wipEntry)
      }
      router.goBackSafely('/', true)
      showToast("Entry saved!")
    }

    const back = () => {
      router.goBackSafely('/', false)
    }

    return {
      wipEntry,
      store,
      loading,
      next,
      back
    }
  }
})
