
import { WipEntry } from '@/models/ephemeral/add-entry';
import { keyWipEntry } from '@/views/AddEditEntry.vue';
import TextareaExpandable from '@/components/AddEntry/TextareaExpandable.vue';
import DateInput from '@/components/general/DateInput.vue';
import { computed, defineComponent, inject as vueInject, onMounted, ref } from 'vue';
import { LevelSet } from '@/models/models';
import { StoreService, useServiceInjector } from '@/injection';

const charCodeIsDigit = (c: number) => {
  return 47 < c && c < 58;
}

const regex = new RegExp('((10|[1-9])-(10|[1-9])-(10|[1-9])-(10|[1-9]))(([^0-9]|[\\n\\r])*)$');

export default defineComponent({
  components: {
    DateInput,
    TextareaExpandable
  },
  emits: ['next', 'back'],
  setup() {
    const formData = vueInject<WipEntry>(keyWipEntry);
    if (formData == null) {
      throw new Error("WIP Entry not found!");
    }

    const spiritual = ref(formData.levels.spiritual);
    const physical = ref(formData.levels.physical);
    const social = ref(formData.levels.social);
    const mental = ref(formData.levels.mental);

    const prefix = ref("");
    const hasPrefix = computed(() => prefix.value !== '')
    const notes = ref(formData.notes);

    const fullNotes = computed({
      set: (v) => {
        if (hasPrefix.value &&
          prefix.value === v.substring(0, prefix.value.length) &&
          !charCodeIsDigit(v.charCodeAt(prefix.value.length))) {
          notes.value = v.substring(prefix.value.length);
          return;
        }
        const [hasMatch, prefixVal, physicalVal, spiritualVal, mentalVal, socialVal, rest] = regex.exec(v) || [];
        if (hasMatch) {
          prefix.value = prefixVal;
          spiritual.value = +spiritualVal;
          physical.value = +physicalVal;
          mental.value = +mentalVal;
          social.value = +socialVal;
          notes.value = rest;
        }
        else {
          notes.value = v;
          prefix.value = '';
        }
      },
      get: () => {
        return (hasPrefix.value ? [physical.value, spiritual.value, mental.value, social.value].join('-') : '') + notes.value;
      }
    });

    const focus = ref(false);

    const inject = useServiceInjector()

    const store = inject(StoreService)

    onMounted(() => {
      if (store.hasDataSync.value) {
        focus.value = true;
      }
    })

    const date = ref<Date|null>(!formData.isNew ? formData.datetime : null);

    return {
      focus,
      notes,
      spiritual,
      physical,
      social,
      mental,
      formData,
      date,
      fullNotes,
      hasPrefix,
      prefix
    }
  },
  methods: {
    next() {
      this.formData.levels = new LevelSet(
        this.physical,
        this.spiritual,
        this.mental,
        this.social
      )
      this.formData.notes = this.notes.trim()
      this.formData.datetime = this.date || new Date()
      this.$emit('next')
    },
    back() {
      this.$emit('back')
    },
    updatePhysical(e: Event) {
      this.physical = Math.max(1, Math.min(10, +(e.target as HTMLInputElement).value));
    },
    updateMental(e: Event) {
      this.mental = Math.max(1, Math.min(10, +(e.target as HTMLInputElement).value));
    },
    updateSpiritual(e: Event) {
      this.spiritual = Math.max(1, Math.min(10, +(e.target as HTMLInputElement).value));
    },
    updateSocial(e: Event) {
      this.social = Math.max(1, Math.min(10, +(e.target as HTMLInputElement).value));
    },
    incrementPhysical() {
      this.physical = Math.max(1, Math.min(10, this.physical + 1))
    },
    incrementMental() {
      this.mental = Math.max(1, Math.min(10, this.mental + 1))
    },
    incrementSocial() {
      this.social = Math.max(1, Math.min(10, this.social + 1))
    },
    incrementSpiritual() {
      this.spiritual = Math.max(1, Math.min(10, this.spiritual + 1))
    },
    decrementPhysical() {
      this.physical = Math.max(1, Math.min(10, this.physical - 1))
    },
    decrementMental() {
      this.mental = Math.max(1, Math.min(10, this.mental - 1))
    },
    decrementSocial() {
      this.social = Math.max(1, Math.min(10, this.social - 1))
    },
    decrementSpiritual() {
      this.spiritual = Math.max(1, Math.min(10, this.spiritual - 1))
    },
  }
})
