<template>
  <div class="textarea-expandable">
    <textarea :value="modelValue" @input="onInput" :placeholder="placeholder" autocomplete="off" ref="ta"/>
    <div class="textarea-inner"><slot>{{modelValue}}</slot>{{" "}}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, watch } from 'vue'
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      required: false
    },
    modelValue: {
      type: String,
      required: true
    },
    focus: { //Triggers focus when changes to true
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    const ta = ref<HTMLTextAreaElement | undefined>()

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onInput = (e: any /* InputEvent */) => {
      emit('update:modelValue', e.target.value);
    }

    watch(toRef(props, 'focus'), (v: boolean) => {
      if (v) ta.value?.focus()
    })

    return {
      ta,
      onInput
    }
  }
})
</script>

<style scoped>
.textarea-expandable {
  position: relative;
  min-height: 100px;
  margin: 4px 0;
  border: 2px solid var(--color-bg);
  background-color: var(--color-bg-dark);
  border-radius: 8px;
}
.textarea-expandable::before,
.textarea-expandable::after {
  position: absolute;
  top: 8px;
  bottom: 8px;
  width: 4px;
  background-color: var(--color-bg);
  content: '';
  z-index: 1;
}
.textarea-expandable::after {
  right: -2px;
}
.textarea-expandable::before {
  left: -2px;
}
textarea {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  resize: none;
  display: block;
  border: 2px solid var(--white-transparent);
  background-color: transparent;
  border-radius: 8px;
  margin: 0;
  color: var(--white);
  overflow: hidden;
}
textarea:focus {
  border-color: var(--white);
}
textarea,
.textarea-inner {
  padding: 4px 6px;
  font-size: 16px;
  font-family: var(--ff-body);
  font-size: 16px;
  line-height: 1.5;
}
.textarea-inner {
  color: var(--white-transparent);
  height: 100%;
  width: 100%;
  white-space: pre-wrap;
  border: 2px solid transparent;
  overflow-wrap: break-word;
}
</style>