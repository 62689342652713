<template>
  <div>
    <label class="field-name" for="when">Date and Time</label>
    <input :id="'when' + id" placeholder="Enter a time, such as '15 minutes ago'"
            :value="textDate"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            autocomplete="off"/>
    <small v-show="isFocused && parsedDateAsText !== textDate" ref="result">
        {{parsedDateAsText}}
    </small>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref, watch } from 'vue'

import { parse } from 'chrono-node'

import { dateToString } from '@/utils/date-utils'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: Date
    }
  },
  setup(props) {
    const instance = getCurrentInstance();

    const date = (props.modelValue as unknown as Date);
    const textDate = ref(date == null ? "Right now" : dateToString(date));

    const parsedDateAsText = ref("");

    const isFocused = ref(false);
    const inputIsValid = ref(true);

    const updateParsed = (d: Date | null, valid = true) => {
      instance?.emit('update:modelValue', d);
      inputIsValid.value = valid;
      if (d != null)
        parsedDateAsText.value = dateToString(d);
      else
        parsedDateAsText.value = '';
    }

    watch(textDate, (v) =>  {
      if (v === '' || v === 'Right now') {
        updateParsed(null);
        return;
      }
      const results = parse(v);
      if (results.length > 0) {
        const d = results[0].date();
        updateParsed(d);
      }
      else {
        updateParsed(null, false);
      }
    });

    watch(parsedDateAsText, v => {
      if (v !== '')
        (instance?.refs['result'] as HTMLElement).scrollIntoView()
    })

    return {
      textDate,
      parsedDateAsText,
      isFocused,
      inputIsValid
    }
  },
  methods: {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onInput(e: any /* InputEvent */) {
      this.textDate = e.target.value
    },
    onFocus() {
      const old = this.textDate;
      this.textDate = (old === 'Right now') ? '' : old;
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
      const old = this.textDate;
      if (old === '' || !this.inputIsValid) {
        this.textDate = 'Right now';
      }
      else if (this.parsedDateAsText !== '') {
        this.textDate = this.parsedDateAsText;
      }
      this.parsedDateAsText = '';
    }
  }
})
</script>