
import { defineComponent, ref, toRef, watch } from 'vue'
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      required: false
    },
    modelValue: {
      type: String,
      required: true
    },
    focus: { //Triggers focus when changes to true
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    const ta = ref<HTMLTextAreaElement | undefined>()

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onInput = (e: any /* InputEvent */) => {
      emit('update:modelValue', e.target.value);
    }

    watch(toRef(props, 'focus'), (v: boolean) => {
      if (v) ta.value?.focus()
    })

    return {
      ta,
      onInput
    }
  }
})
